<template lang="pug">
  #GarantiaFalla
      b-card.form-card
        b-row.pb-2
          span#icon 2
          h5.title.mt-2 Tipo de Garantia y Falla
        //- hr
        b-row.pt-4
          b-col(cols="12" md='3')
            b-form-group(class="servicio" label-for="input-servicio")
              template( #label ) Tipo de Servicio:
              b-select(
                id="input-servicio"
                v-model="formGarantia.tipo_servicio"
                :options="serviceOptions"
              )
          b-col(cols="12")
            b-form-group(class="falla" label-for="input-falla" :label-class="activeField === 'falla' ? 'active-label' : ''")
              template( #label ) Reporte de falla:
              b-form-input(
                id="input-falla"
                v-model="formGarantia.reporte_falla_cliente"
                placeholder="Describe qué problema tiene tu equipo."
                :state="$v.formGarantia.reporte_falla_cliente.$dirty? !$v.formGarantia.reporte_falla_cliente.$invalid : null"
                @focus="activeField = 'falla'"
                @blur="activeField = ''"
              )
              .errors(v-if="$v.formGarantia.reporte_falla_cliente.$dirty")
                b-form-invalid-feedback(:state="$v.formGarantia.reporte_falla_cliente.required")
                  | Campo requerido
          b-col(cols="12" md='3')
            b-form-group(v-show="formGarantia.tipo_servicio == 1" class="fecha" label-for="input-fecha")
              template( #label ) Fecha de compra:
              b-form-datepicker(
                id="input-fecha"
                v-model='formGarantia.fecha_de_compra'
                placeholder = "Seleccione una fecha"
                select-variant="primary"
              )
          b-col(cols="12" md='3')
        b-form-group(v-show="formGarantia.tipo_servicio == 1")
          label(class="client-form-inputs" for="input-adjuntar") Adjuntar datos de Boleta:
          b-form-group(class="UploadFile")
            UploadFilePreIngreso(:setBoleta="setBoleta")
        .d-flex.justify-content-between.mt-2
          b-button.btn-create.secondary-btn(@click="goBack()" small variant="outline")
            | Anterior
          b-button.btn-create.primary-btn(@click="onSubmit()" small variant="samtek-yellow")
            | Siguente Paso
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators'
import UploadFilePreIngreso from './UploadFilePreIngreso.vue'

export default {
  props: ['setDatosGarantia', 'guardarBoleta', 'garantiaReturn'],
  components: {
    UploadFilePreIngreso,
  },
  data() {
    return {
      activeField: false,
      formGarantia: {
        tipo_servicio: this.garantiaReturn.tipo_servicio
          ? this.garantiaReturn.tipo_servicio
          : null,
        reporte_falla_cliente: this.garantiaReturn.reporte_falla_cliente
          ? this.garantiaReturn.reporte_falla_cliente
          : '',
        fecha_de_compra: this.garantiaReturn.fecha_de_compra
          ? this.garantiaReturn.fecha_de_compra
          : null,
      },
      boleta: '',
      serviceOptions: [
        { value: null, text: 'Seleccione' },
        { value: 1, text: 'Garantía' },
        { value: 4, text: 'Presupuesto' },
      ],
    }
  },
  validations: {
    formGarantia: {
      reporte_falla_cliente: {
        required: requiredIf(function f() {
          return this.formGarantia.tipo_servicio === 4
        }),
      },
    },
  },
  methods: {
    async onSubmit() {
      if (this.$v.$invalid) {
        this.$bvToast.toast('Rellene todos los campos requeridos', {
          variant: 'danger',
        })
        this.$v.$touch()
      } else {
        this.setDatosGarantia(this.formGarantia)
        this.guardarBoleta(this.boleta)
        this.$bvToast.toast('Datos guardado exitosamente', {
          variant: 'success',
        })
      }
      console.log(this.boleta)
    },
    setBoleta(boleta) {
      this.boleta = boleta
    },
    goBack() {
      this.$emit('goBack')
    },
  },
  watch: {
    garantiaReturn: {
      handler() {
        this.formGarantia = {
          tipo_servicio: this.garantiaReturn.tipo_servicio
            ? this.garantiaReturn.tipo_servicio
            : null,
          reporte_falla_cliente: this.garantiaReturn.reporte_falla_cliente
            ? this.garantiaReturn.reporte_falla_cliente
            : '',
          fecha_de_compra: this.garantiaReturn.fecha_de_compra
            ? this.garantiaReturn.fecha_de_compra
            : null,
        }
      },
      deep: true,
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/colors.scss';

::v-deep.form-card {
  box-shadow: 0 3px 6px 0 #00000029;
  opacity: 1;
  padding: 15px;
  .title {
    letter-spacing: 0px;
    color: $samtek-black;
    opacity: 1;
    font-weight: 700;
  }
}
.form-group::v-deep label {
  color: $samtek-grey;

  &.active-label {
    color: $samtek-black;
  }
}
#icon {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  background-color: $samtek-yellow;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: $samtek-black;
  font-weight: 700;
}
.btn-create {
  font-weight: 600;
  color: $samtek-black;
}
.secondary-btn {
  border-color: $samtek-yellow;
}
</style>
