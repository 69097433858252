<template lang="pug">
div
  h2.main-title {{ title }}
  b-row
    b-col(cols='3')
      .form-subtitle
        font-awesome-icon(icon="portrait" size="lg")
        h3 {{ quote.id ? 'Datos Cliente' : 'Mis Datos' }}
      hr
      .flex-col.gap-2
        .input-group.em
          h6 R.U.T
          p {{ quote.quote_client.rut }}
        .input-group
          h6 Nombre
          p {{ quote.quote_client.first_name }}
        .input-group
          h6 Apellido
          p {{ quote.quote_client.last_name }}
        .input-group
          h6 Región
          p {{ quote.quote_client.region }}
        .input-group
          h6 Comuna
          p {{ quote.quote_client.commune }}
        .input-group
          h6 Dirección
          p {{ quote.quote_client.address }}
        .input-group
          h6 Email
          p {{ quote.quote_client.email }}
        .input-group
          h6 Teléfono
          p {{ quote.quote_client.phone }}

    b-col(cols='3')
      .form-subtitle
        font-awesome-icon(icon="laptop" size="lg")
        h3 Datos del Equipo
      hr
      .flex-col.gap-2
        .input-group.em
          h6 N° de Serie
          p {{ quote.device_serial_number }}
        .input-group
          h6 Imagen Etiqueta
          img.device-image(:src="imageUrl" alt="Imagen Etiqueta")

    b-col(cols='6')
      .form-subtitle
        font-awesome-icon(icon="wrench" size="lg")
        h3 Repuestos
      hr
      .flex-col.gap-3
        div(v-for="part, idx in quote.parts" :key="`${idx}-${part.category_id}-${part.comments}`")
          .part-subtitle.mb-4 Respuesto {{ idx + 1 }}
          .flex-col.gap-2
            .input-group
              h6 Categoría del Repuesto
              p {{ getPartName(part) }}
            .input-group
              h6 Comentarios
              p {{ part.comments }}
</template>

<script>
import { mapActions } from 'pinia'
import { useExternalQuoteStore } from '@/pinia/api/useExternalQuoteStore'

export default {
  props: {
    quote: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      categories: [],
    }
  },
  computed: {
    title() {
      return this.quote?.id
        ? `Solicitud de Presupuesto N° ${this.quote.id}`
        : 'Resumen Solicitud de Presupuesto'
    },

    imageUrl() {
      if (typeof this.quote.device_picture === 'string') return this.quote.device_picture
      return URL.createObjectURL(this.quote.device_picture)
    },
  },
  methods: {
    ...mapActions(useExternalQuoteStore, ['listPartCategories']),
    getPartName(part) {
      const id = part.category || part.category_id
      return this.categories.find((cat) => cat.id === id)?.name || '-'
    },
  },
  async created() {
    const res = await this.listPartCategories()
    this.categories = res.data
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/colors.scss';

.main-title {
  color: #525252;
  font-family: Noto Sans;
  font-size: 24px;
  font-weight: 700;
  line-height: 32.69px;
  text-align: left;
  margin-bottom: 1em;
}

.form-subtitle {
  color: $samtek-black;
  display: flex;
  align-items: center;
  gap: 0.5em;
  padding-left: 0.5em;

  > h3 {
    font-weight: bold;
    font-size: 18px;
    margin: 0;
  }

  + hr {
    margin: 0.5em 0 1.5em;
  }
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 0.3em;

  & > h6 {
    color: #525252;
    font-weight: bold;
    margin: 0;
  }
  & > p {
    font-size: 1em;
    margin: 0;
  }

  &.em > h6 {
    color: $samtek-black;
  }
}

.device-image {
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #cbcbcb;
  margin-top: 5px;
}

.part-subtitle {
  color: $samtek-black;
  font-size: 18px;
  font-weight: 700;
  line-height: 24.52px;
  text-align: left;
}
</style>
