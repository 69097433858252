<template lang="pug">
  .filePart
    b-col(id="file")
      .containerInputFile.pb-4
        input(
          type="file"
          id="filePart" ref="file"
          accept=".pdf,.png,.jpg,.jpeg,.tiff,.gif"
          @change="handleFileUpload"
        )
        font-awesome-icon.cloud-icon.mb-2.mt-4(icon="cloud-upload-alt" size="3x")
        label(class="labelDocumento") {{ file ? file.name : 'NO HAY ARCHIVO CARGADO' }}
        span Subir archivo
</template>

<script>
export default {
  name: 'UploadFilePreIngreso',
  props: ['setBoleta'],
  data() {
    return {
      files: [],
      file: '',
    }
  },
  computed: {},
  methods: {
    handleFileUpload() {
      this.file = this.$refs.file.files[0] // eslint-disable-line
      this.setBoleta(this.file)
    },
    async submitFile() {
      const formData = new FormData()
      if (
        this.file.type !== 'image/jpeg' &&
        this.file.type !== 'image/png' &&
        this.file.type !== 'application/pdf'
      ) {
        this.$bvToast.toast('Solo se permiten archivos .jpg, .png, .pdf ')
        console.log(formData)
        return
      }
      this.setBoleta(this.file)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/colors.scss';

.filePart {
  display: flex;
  border: 3px dashed gray;
  border-radius: 1rem;
  width: 100%;
  position: relative;
  height: 20vh;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
.filePart span {
  background-color: $samtek-yellow;
  border-radius: 8px;
  color: $samtek-black;
  padding: 1rem;
  font-weight: 600;
}
.containerInputFile input[type='file'] {
  height: 20vh;
  opacity: 0;
  position: absolute;
  /* top: -55px; */
  bottom: 0;
  left: 0;
  right: 0;
}
.containerInputFile {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.cloud-icon {
  color: $samtek-yellow;
}
</style>
