<template lang="pug">
Fragment
  b-form-group
    label(class="client-form-inputs" for="input-modelos") Modelo
    .d-flex.gap-3.flex-column.flex-sm-row.align-items-center
      vue-typeahead-bootstrap.w-100(
        v-model="model"
        :data="modelsOptions"
        :serializer="s => s.text"
        @hit="handleHit"
        :placeholder="showModel"
        :disabled="disabled"
        :input-class="state === false ? 'is-invalid' : (state ? 'is-valid' : null)"
      )
      slot( name="invalid-feedback")
      b-button.btn-nuevo(
        @click="showCreateModal = true, createForm = { years_warranty: 1 }" :disabled="disabled" variant="samtek-yellow"
      ) Nuevo...
  //- Modal Crear Marca
  b-modal.create-modal-brand(
    v-model="showCreateModal"
    :title="showCreateModalBrand ? 'Agregar marca' : 'Crear Equipo'"
    :hide-footer="showCreateModalBrand"
    ok-title="Crear"
    :disabled="loading"
    @ok="onSubmit"
  )
    b-form(v-show="showCreateModalBrand")
      b-row
        b-col
          b-form-group(
            id="input-group-newbramd"
            label="Marca"
            label-for="input-new-brand"
          )
            b-form-input(
              id="input-new-brand"
              v-model="newBrand"
              @input="$v.newBrand.$touch()"
              :state="$v.newBrand.$dirty ? !$v.newBrand.$invalid : null"
            )
            .errors(v-if="$v.newBrand.$dirty")
              b-form-invalid-feedback(:state="$v.newBrand.required")
                | Campo requerido
      b-row
        b-col.d-flex.flex-row
          b-button.ml-auto(@click="onSubmitNewBrand" :disabled="loading") Agregar Marca
    b-form(v-show="!showCreateModalBrand")
      b-row
        b-col
          b-form-group(
            id="input-group-equipmentType"
            label="Tipo de Equipo"
            label-for="input-equipmentType"
          )
            b-form-select(
              id="input-equipmentType"
              :options="equipTypeOptions"
              v-model="createForm.dispositive_type"
              @input="$v.createForm.dispositive_type.$touch()"
              :state="$v.createForm.dispositive_type.$dirty ? !$v.createForm.dispositive_type.$invalid : null"
            )
              template( #item="{ item }" )
            .errors(v-if="$v.createForm.dispositive_type.$dirty")
              b-form-invalid-feedback(:state="$v.createForm.dispositive_type.required")
                | Campo requerido
      b-row
        b-col
          b-form-group(
            id="input-group-part_number"
            label="Part Number"
            label-for="input-part_number"
          )
            b-form-input(
              id="input-part_number"
              v-model="createForm.part_number"
              placeholder="Ingrese número de parte..."
              @input="$v.createForm.part_number.$touch()"
              :state="$v.createForm.part_number.$dirty ? !$v.createForm.part_number.$invalid : null"
            )
            .errors(v-if="$v.createForm.part_number.$dirty")
              b-form-invalid-feedback(:state="$v.createForm.part_number.required")
                | Campo requerido
        b-col
          b-form-group(
            id="input-group-model"
            label="Años de garantía"
            label-for="input-brand"
          )
            b-form-select(
              id="input-brand"
              v-model="createForm.years_warranty"
              :options="yearsOptions"
            )
      b-row
        b-col
          b-form-group(
            id="input-group-model"
            label="Marca"
            label-for="input-brand"
          )
            b-form-select(
              id="input-brand"
              v-model="createForm.marca"
              :options="brandOptions"
              :state="$v.createForm.marca.$dirty ? !$v.createForm.marca.$invalid : null"
              @change="handleBrandChange"
            )
            .errors(v-if="$v.createForm.marca.$dirty")
              b-form-invalid-feedback(:state="$v.createForm.marca.required")
                | Campo requerido
        b-col
          b-form-group(
            id="input-group-model"
            label="Nombre Modelo"
            label-for="input-model"
          )
            b-form-input(
              id="input-model"
              v-model="createForm.model_name"
              :state="$v.createForm.model_name.$dirty ? !$v.createForm.model_name.$invalid : null"
              @input="$v.createForm.model_name.$touch()"
            )
            .errors(v-if="$v.createForm.model_name.$dirty")
              b-form-invalid-feedback(:state="$v.createForm.model_name.required")
                | Campo requerido
    template(#modal-footer="{ ok, cancel}")
      b-button.secondary-btn(@click="cancel" variant="outline") Cancelar
      b-button.primary-btn(@click="ok" variant="samtek-yellow") Crear
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators/'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import { equipTypeOptions } from '../options'

export default {
  components: {
    VueTypeaheadBootstrap,
  },
  name: 'Modelos',
  props: ['modelo_id', 'disabled', 'inputName', 'state'],
  validations: {
    createForm: {
      marca: { required },
      dispositive_type: { required },
      model_name: { required },
      part_number: { required },
    },
    newBrand: { required },
  },
  data() {
    return {
      selected_model: this.modelo_id,
      showCreateModal: false,
      showCreateModalBrand: false,
      createForm: {
        marca: null,
        dispositive_type: null,
        model_name: null,
        part_number: null,
        years_warranty: 1,
      },
      equipTypeOptions,
      yearsOptions: [1, 2, 3, 4, 5],
      newBrand: null,
      model: null,
      loading: false,
    }
  },
  computed: {
    ...mapGetters('EquipmentStore', ['models', 'brands', 'equipment']),
    modelsOptions() {
      return this.models.map((val) => {
        const { dispositive_type, model_name, part_number } = val
        let typeText = equipTypeOptions.find(
          (t) => t.value === dispositive_type
        )
        typeText = typeText ? typeText.text : '-'
        return {
          value: val.id,
          text: `${typeText} - ${model_name} - ${part_number}`,
        }
      })
    },
    brandOptions() {
      return this.brands
        .map((val) => ({ value: val.id, text: val.marca_name }))
        .concat([
          {
            value: 'nuevo',
            text: 'Crear...',
          },
        ])
    },
    showModel() {
      if (!this.equipment) return 'Escriba nombre del modelo...'
      return this.equipment.modelo_detail
        ? `${this.equipment.modelo_detail.dispositive_type} - ${this.equipment.modelo_detail.model_name}`
        : 'Escriba nombre del modelo...'
    },
  },
  methods: {
    ...mapActions('EquipmentStore', [
      'getModels',
      'getBrands',
      'createModel',
      'createBrand',
    ]),
    handleHit(event) {
      this.$emit('dataModel', event)
      this.selected_model = event.value
      const value = event.value // eslint-disable-line
      if (value === 'nuevo') {
        this.showCreateModal = true
        this.createForm = { years_warranty: 1 }
      } else {
        this.selected_model = value
        this.$emit('onModelSelect', value)
      }
    },
    fetchModels() {
      this.selected_model = this.modelo_id
      this.getBrands()
      this.getModels()
    },
    handleModelChange(modelId) {
      if (modelId === 'nuevo') {
        this.showCreateModal = true
        this.createForm = { years_warranty: 1 }
      } else {
        console.log('emiting onModelSelect', modelId)
        this.selected_model = modelId
        this.$emit('onModelSelect', modelId)
      }
    },
    async onSubmit() {
      if (this.$v.createForm.$invalid) {
        this.$bvToast.toast('Rellene todos los campos requeridos', {
          variant: 'danger',
        })
        this.$v.$touch()
      } else {
        this.loading = true
        const action = this.createModel
        try {
          const res = await action(this.createForm)
          this.loading = false
          this.$bvToast.toast('Modelo guardado exitosamente', {
            variant: 'success',
          })
          await this.getModels()
          const { dispositive_type, model_name, part_number, id } =
            res.data.Modelo
          let typeText = equipTypeOptions.find(
            (t) => t.value === dispositive_type
          )
          typeText = typeText ? typeText.text : '-'
          this.model = `${typeText} - ${model_name} - ${part_number}`
          this.selected_model = id
          this.$emit('onModelSelect', id)
        } catch (err) {
          this.$bvToast.toast(`Error al guardar: ${err}`, {
            variant: 'danger',
          })
        }
      }
    },
    async onSubmitNewBrand() {
      this.loading = true
      if (this.$v.newBrand.$invalid) {
        this.$bvToast.toast('Rellene todos los campos requeridos', {
          variant: 'danger',
        })
      } else {
        const res = await this.createBrand({
          marca_name: this.newBrand,
        })
        this.createForm.marca = res.data.id
        await this.getBrands()
        this.showCreateModalBrand = false
      }
      this.loading = false
    },
    handleBrandChange() {
      this.$v.createForm.marca.$touch()
      if (this.createForm.marca === 'nuevo') {
        this.showCreateModalBrand = true
        this.newBrand = ''
      }
    },
  },
  created() {
    this.fetchModels()
  },
  watch: {
    equipment() {
      this.fetchModels()
    },
    modelo_id() {
      this.selected_model = this.modelo_id
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/style/colors.scss';

.form-group::v-deep label {
  color: $samtek-grey;

  &.active-label {
    color: $samtek-black;
  }
}
.client-form-inputs {
  color: $samtek-grey;
}

.btn-nuevo {
  max-width: 160px;
  width: 100%;
  font-weight: 600;
}
.primary-btn {
  color: $samtek-black;
  font-weight: 600;
}
.secondary-btn {
  color: $samtek-black;
  border-color: $samtek-yellow;
  font-weight: 600;
}
</style>
