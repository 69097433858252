import { render, staticRenderFns } from "./TabDeviceData.vue?vue&type=template&id=acf2b6a4&scoped=true&lang=pug"
import script from "./TabDeviceData.vue?vue&type=script&lang=js"
export * from "./TabDeviceData.vue?vue&type=script&lang=js"
import style0 from "./TabDeviceData.vue?vue&type=style&index=0&id=acf2b6a4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "acf2b6a4",
  null
  
)

export default component.exports