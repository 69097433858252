<template lang="pug">
  .bottom-wrapper
    .container
      hr.w-100.m-0
      span.footer-text © {{ currentYear }} Samtek | Servicio Técnico ASUS DELL Autorizado en Chile.
</template>
<script>
export default {
  name: 'Footer',
  computed: {
    currentYear() {
      return new Date().getFullYear()
    },
  }
}
</script>
<style lang="scss" scoped>
.bottom-wrapper .container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer-text {
  margin: 12px 0;
}
</style>