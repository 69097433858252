<template lang="pug">
  div
    .d-flex.justify-content-between
      .d-flex.justify-content-start
        b-icon.mr-2(icon="tools" variant="primary" font-scale="1.5")
        span.title Historial Movimientos de Inventario
    hr

    b-form-checkbox(v-model="switchTableOld" switch) Mostrar tabla antigua

    b-table(:items="statesItemsOld" :busy="loading" hover responsive v-show="switchTableOld")
      template(#table-busy)
            div(class="text-center text-danger my-2")
              b-spinner(class="align-middle")
    h4.text-center(v-if="statesItemsOld.length === 0") No hay registros

    b-table(:items="statesItems" :busy="loading" hover responsive :fields="fields" v-show="!switchTableOld")
      template(#table-busy)
        div(class="text-center text-danger my-2")
          b-spinner(class="align-middle")
      template(#cell(solicitud)="{value}")
        span {{ value | formatDate }}
      template(#cell(llegada)="{value}")
        span {{ value | formatDate }}
      template(#cell(entrega)="{value}")
        span {{ value | formatDate }}
      template(#cell(Drop)="{item}")
        .flex-col(v-if="item.Drop")
          span {{ item.Drop.fecha | formatDate }}
          span {{ item.Drop.user }}
        span(v-else) -
      template(#cell(Bad)="{item}")
        .flex-col(v-if="item.Bad")
          span {{ item.Bad.fecha | formatDate }}
          span {{ item.Bad.user }}
        span(v-else) -

    h4.text-center(v-if="statesItems.length === 0") No hay registros
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex'

export default {
  name: 'InventoryMovementsHistory',
  props: ['otId'],
  data() {
    return {
      movements: [],
      movementsOld: [],
      loading: false,
      loadingOld: false,
      switchTableOld: true,
    }
  },
  async created() {
    this.loading = true
    // await this.getInventoryMovementsOt(this.otId).then((res) => {
    await this.getInventoryMovementHistory(this.otId).then((res) => {
      this.movements = res.data
    })
    this.loading = false
    await this.getInventoryMovementsOt(this.otId).then((res) => {
      this.movementsOld = res.data
    })
    this.loadingOld = false
  },
  methods: {
    ...mapActions('OT', [
      'getInventoryMovementsOt',
      'getInventoryMovementHistory',
    ]),
  },
  computed: {
    fields() {
      return [
        { key: 'part_number', label: 'Código' },
        { key: 'description', label: 'Descripción' },
        { key: 'real_location', label: 'Ubicación real' },
        { key: 'solicitud', label: 'Petición' },
        { key: 'cantidad', label: 'Cantidad' },
        { key: 'llegada', label: 'Llegada' },
        { key: 'entrega', label: 'Entrega' },
        { key: 'Drop', label: 'Drop' },
        { key: 'Bad', label: 'Bad' },
      ]
    },
    statesItems() {
      return this.movements
    },
    statesItemsOld() {
      return this.movementsOld.map((e) => ({
        'Fecha ': new Date(e.created_at).toLocaleString(),
        'Código ': e.parte.part_number,
        'Descripción': e.parte.description,
        'Ubicación real ': e.parte.real_location,
        'Tipo ': e.tipo,
        'Cantidad ': e.cantidad,
        'Usuario ': e.user ? e.user : '',
      }))
    },
  },
  filters: {
    formatDate(value) {
      if (!value) return '-'
      return moment(value).format('DD/MM/YYYY hh:mm a')
    },
  },
}
</script>
<style lang="scss" scoped>
.title {
  color: #253590;
  font-size: 1.2em;
  font-weight: bold;
}
</style>
