<template lang="pug">
#DataEquipQuotations
    b-row
      b-col(cols="12" md="6")
        b-form-group(label-for="input-serie" :label-class="activeField === 'serie' ? 'active-label' : ''")
          template( #label )
            span.em-label N° de serie del equipo:
          b-form-input(
            id="input-serie"
            v-model="formEquip.serial_number"
            placeholder="Ej:10200300-9"
            :state="$v.formEquip.serial_number.$dirty? !$v.formEquip.serial_number.$invalid : null"
            @focus="activeField = 'serie'"
            @blur="activeField = ''"
          )
          .errors(v-if="$v.formEquip.serial_number.$dirty")
            b-form-invalid-feedback(:state="$v.formEquip.serial_number.required")
              | Campo requerido

    b-form-group(
      label= "Adjunta una imagen de la etiqueta ubicada en la parte de abajo del equipo"
      label-for="input-document"
    )
      .filePart(
        v-if="!serialImg"
        ref="dragContainer"
        draggable @dragstart=""
        @dragenter="fileDragEnter"
        @dragleave="fileDragLeave"
        @drop="fileDragLeave"
      )
        b-col(id="file")
          .containerInputFile
            input(type="file" id="filePart" ref="file"  @change="handleFileUpload")
            font-awesome-icon.cloud-icon.mb-3(icon="cloud-upload-alt" size="3x")
            label(class="labelDoc") {{ this.serialImg ? this.serialImg.name : 'Arrastra la imagen o haz click aquí' }}
            span.form-btn.flex-row.align-items-center.justify-content-center Subir Archivos
      b-col(v-else cols="12" md="6")
        img(:src="imageUrl" alt="Imagen Etiqueta" style="width: auto; max-width: 400px; max-height: 200px;")

    .d-flex.justify-content-between.mt-5
        b-button.form-btn.secondary-btn(@click="goBack()" small variant="outline")
          | Anterior
        b-button.form-btn.primary-btn(@click="onSubmit()" variant="samtek-yellow" small)
          | Siguiente Paso
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'DataEquipQuotations',
  props: ['setFormEquip', 'returnSerialImg', 'returnSerialNumber'],
  data() {
    return {
      activeField: false,
      serialImg: this.returnSerialImg.serialImg,
      formEquip: {
        serial_number: this.returnSerialNumber.serial_number,
      },
    }
  },
  validations: {
    formEquip: {
      serial_number: { required },
    },
  },
  computed: {
    imageUrl() {
      return URL.createObjectURL(this.serialImg)
    },
  },
  methods: {
    onSubmit() {
      if (this.$v.$invalid) {
        this.$bvToast.toast('Complete todo los campos', {
          variant: 'danger',
        })
        this.$v.$touch()
      } else {
        this.setFormEquip(this.formEquip.serial_number)
        this.returnSerialImg(this.serialImg)
      }
    },
    goBack() {
      this.$emit('goBack')
    },
    handleFileUpload() {
      this.serialImg = this.$refs.file.files[0] // eslint-disable-line
      console.log(this.serialImg)
    },
    fileDragEnter(event) {
      console.log('drag enter', event)
      event.preventDefault()
      this.$refs.dragContainer.classList.add('file-dragover')
    },
    fileDragLeave(event) {
      console.log('drag leave', event)
      event.preventDefault()
      this.$refs.dragContainer.classList.remove('file-dragover')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/colors.scss';

::v-deep.form-card {
  box-shadow: 0 3px 6px 0 #00000029;
  opacity: 1;
  padding: 15px;

  .title {
    letter-spacing: 0px;
    color: $samtek-black;
    opacity: 1;
    font-weight: 600;
  }
}
.form-group::v-deep label {
  color: $samtek-grey;

  &.active-label {
    color: var(--primary);
  }
}

::v-deep .filePart {
  display: flex;
  border: 3px dashed gray;
  border-radius: 1rem;
  width: 100%;
  position: relative;
  /* height: 20vh; */
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
::v-deep .filePart span {
  background-color: $samtek-yellow;
  border-radius: 3px;
  color: $samtek-black;
  padding: 1rem;
  font-weight: 600;
}
::v-deep .containerInputFile input[type='file'] {
  /* height: 20vh; */
  opacity: 0;
  position: absolute;
  top: -55px;
  bottom: 0;
  left: 0;
  right: 0;
}
::v-deep .containerInputFile {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 1.8rem;
  padding-bottom: 1.5rem;
}
.em-label {
  color: $samtek-black;
}
.file-dragover {
  border: 3px dashed pink !important;
  background-color: #f0f0f0;
}
.cloud-icon {
  color: $samtek-yellow;
}
.secondary-btn {
  border-color: $samtek-yellow;
  color: $samtek-black;
  font-weight: 600;
}
.primary-btn {
  font-weight: 600;
}
</style>
