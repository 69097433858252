import axios from '../../plugins/axios'

export const listOTClient = ({ commit }) => {
  const apiAddress = ''
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  } // Cuando este listo el back coloco la configuracion para el header del request
  return axios.get(apiAddress, config).then((response) => {
    const OTClient = response.data
    commit('SET_OTCLIENT', { OTClient })
  })
}

export const listEmpresas = ({ commit }) => {
  const apiAddress = '/api/clientes/empresa'
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios.get(apiAddress, config).then((response) => {
    const empresas = response.data
    commit('SET_EMPRESAS', { empresas })
  })
}
export const listRegiones = ({ commit }) => {
  const apiAddress = '/api/clientes/regiones'
  return axios.get(apiAddress).then((response) => {
    const regiones = response.data
    commit('SET_REGIONES', { regiones })
    return regiones;
  })
}

export const listOTSClient = ({ commit }) => {
  const apiAddress = '/api/clientes'
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios.get(apiAddress, config).then((response) => {
    const OTSClient = response.data
    commit('SET_OTSCLIENT', { OTSClient })
  })
}

export const createClient = ({ commit }, { data, sucursal }) => {
  const apiAddress = '/api/clientes/'
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios
    .post(apiAddress, data, config)
    .then((response) => {
      const client = response.data
      let hasEmpresa = false
      if (client.clienteEmpresa) {
        hasEmpresa = true
      }
      commit('SET_CLIENT', { client: { ...client, hasEmpresa } })
      commit('SET_SUCURSAL', { sucursal })
      return response
    })
    .catch((error) => error.response)
}

export const getClient = ({ commit }, clientId) => {
  const apiAddress = `/api/clientes/${clientId}?tipo=1`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  } // Cuando este listo el back coloco la configuracion para el header del request
  return axios
    .get(apiAddress, config)
    .then((response) => {
      const client = response.data
      commit('SET_CLIENT', { client })
    })
    .catch((error) => error.response)
}

export const editClient = ({ commit }, { data, sucursal }) => {
  const apiAddress = `/api/clientes/${data.id}`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  } // Cuando este listo el back coloco la configuracion para el header del request
  return axios
    .put(apiAddress, data, config)
    .then((response) => {
      const client = response.data
      let hasEmpresa = false
      if (client.clienteEmpresa) {
        hasEmpresa = true
      }
      commit('SET_CLIENT', { client: { ...client, hasEmpresa } })
      commit('SET_SUCURSAL', { sucursal })
      return response
    })
    .catch((error) => error.response)
}

export const searchByRut = ({}, { rut }) => {
  const sinDigito = rut.split('-')[0]
  const apiAddress = `api/clientes/?query=${sinDigito}`
  console.log({ apiAddress })
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios.get(apiAddress, config).then((response) => response.data)
}

export const getSucursales = ({ commit }, { clientId }) => {
  const apiAddress = `api/clientes/${clientId}/sucursal`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios
    .get(apiAddress, config)
    .then((response) => {
      const sucursales = response.data
      commit('SET_SUCURSALES', { sucursales })
    })
    .catch((error) => error.response)
}

export const createSucursal = ({ commit }, data) => {
  const apiAddress = `api/clientes/${data.cliente_empresa}/sucursal`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios
    .post(apiAddress, data, config)
    .then((response) => {
      commit('SET_SUCURSAL', { sucursal: response.data.sucursal })
    })
    .catch((error) => error.response)
}

export const editSucursal = ({ commit }, payload) => {
  commit('SET_ERROR_SUCURSAL', false)
  const apiAddress = `api/clientes/${payload.cliente_empresa}/sucursal/${payload.sucursal_id}`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  const sucursal = {
    id: payload.sucursal_id,
  }
  return axios
    .put(apiAddress, payload.data, config)
    .then(() => {
      commit('SET_SUCURSAL', { sucursal })
    })
    .catch((error) => {
      console.log(error);
      commit('SET_ERROR_SUCURSAL', true);
    });
};

export const requestChangeDelivery = ({}, data) => {
  const apiAddress = `/api/orden/${data.ot}/solicitar_cambio_retiro`;
  return axios.post(apiAddress, data)
    .then((response) => response)
    .catch((error) => error.response);
};

export const confirmChangeDelivery = ({}, token) => {
  const apiAddress = `/api/orden/${token}/confirmar_cambio_retiro`;
  return axios.post(apiAddress, {})
    .then((response) => response)
    .catch((error) => error.response);
};

export const changeOtDelivery = ({}, data) => {
  const apiAddress = `/api/orden/${data.ot}/cambiar_despacho`;
  return axios.post(apiAddress, data)
    .then((response) => response)
    .catch((error) => error.response);
};

export const createClientNoStore = ({}, { data }) => {
  const url = '/api/clientes/'
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios
    .post(url, data, config)
    .catch((error) => error.response)
}

export const createSucursalNoStore = ({}, data) => {
  const apiAddress = `api/clientes/${data.cliente_empresa}/sucursal`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios
    .post(apiAddress, data, config)
    .catch((error) => error.response)
}
