<template lang="pug">
  .wrapper
    .buttons-filters(v-if="showInDashboard")
      b-button(@click="filters.fase = null" variant="primary" :disabled="loading").filter-btn TOTAL
      b-button(@click="filters.fase = NOKBO" variant="warning" :disabled="loading").filter-btn NOKBO
      b-button(@click="filters.fase = STOP" variant="info" :disabled="loading").filter-btn STOP
      b-button(@click="filters.fase = ALLOCATED" variant="success" :disabled="loading").filter-btn ALLOCATED
    .buttons-filters
      b-button.filter-btn(
        @click="toggleServicio(GARANTIA)"
        variant="outline-primary"
        :class="{ 'active': filters.tipoServicio === GARANTIA }"
        :disabled="loading || isSearching"
      ) Garantía

      b-button.filter-btn(
        @click="toggleServicio(PRESUPUESTO)"
        variant="outline-primary"
        :class="{ 'active': filters.tipoServicio === PRESUPUESTO }"
        :disabled="loading || isSearching"
      ) Presupuesto

      b-button.filter-btn(
        @click="toggleCliente(PERSONA)"
        variant="outline-primary"
        :class="{ 'active': filters.tipoCliente === PERSONA }"
        :disabled="loading || isSearching"
      ) Cliente persona

      b-button.filter-btn(
        @click="toggleCliente(EMPRESA)"
        variant="outline-primary"
        :class="{ 'active': filters.tipoCliente === EMPRESA }"
        :disabled="loading || isSearching"
      ) Cliente empresa

      b-button.filter-btn(
        v-if="!showInDashboard"
        @click="toggleAlertColor(AMARILLA)"
        variant="outline-warning"
        :class="{ 'active': filters.alertColor.includes(AMARILLA) }"
        :disabled="loading"
      ) Alerta Amarilla

      b-button.filter-btn(
        v-if="!showInDashboard"
        @click="toggleAlertColor(ROJA)"
        variant="outline-danger"
        :class="{ 'active': filters.alertColor.includes(ROJA) }"
        :disabled="loading"
      ) Alerta Roja

      b-button.filter-btn(
      @click="toggleOptions()"
      v-if="!hideOnSiteButton"
      variant="outline-info"
      :class="{ 'btn-disabled': !filters.on_site , 'active': filters.on_site }"
      :disabled="loading || isSearching"
      ) On Site

  </template>

  <script>
  import { mapGetters } from 'vuex';
  import { mapState, mapWritableState } from 'pinia'
  import { useDashboardStore } from '../../pinia/views/useDashboardStore'

  export default {
    name: 'ButtonsFilters',

    props: {
      showInDashboard: {
        type: Boolean,
        default: true,
      },
    },

    computed: {
      ...mapWritableState(useDashboardStore, ['filters']),
      ...mapState(useDashboardStore, ['loading']),
      ...mapGetters('OT', ['isSearching']),
      hideOnSiteButton() {
      return this.$route.path === '/listar-ordenes';
      },

      NOKBO: () => 2,
      STOP: () => 5,
      ALLOCATED: () => 3,

      GARANTIA: () => 1,
      PRESUPUESTO: () => 4,
      PERSONA: () => 1,
      EMPRESA: () => 2,
      ON_SITE: () => false,

      AMARILLA: () => 'yellow',
      ROJA: () => 'red',
    },

    methods: {
      toggleServicio(servicio) {
        this.filters.tipoServicio =
          this.filters.tipoServicio === servicio ? null : servicio
      },

      toggleCliente(cliente) {
        this.filters.tipoCliente =
          this.filters.tipoCliente === cliente ? null : cliente
      },
      toggleOptions() {
      this.filters.on_site = !this.filters.on_site;
      },
      toggleAlertColor(color) {
        const i = this.filters.alertColor.indexOf(color)
        if (i === -1) {
          this.filters.alertColor = [...this.filters.alertColor, color]
        } else {
          this.filters.alertColor = this.filters.alertColor.filter((c) => c !== color)
        }
      },
      cleanFilters() {
        this.filters = {
          tipoServicio: null,
          tipoCliente: null,
          fase: null,
          alertColor: [],
          on_site: false
        }
      },
    },
    toggleAlertColor(color) {
      const i = this.filters.alertColor.indexOf(color)
      if (i === -1) {
        this.filters.alertColor = [...this.filters.alertColor, color]
      } else {
        this.filters.alertColor = this.filters.alertColor.filter(
          (c) => c !== color
        )
      }
    },
    cleanFilters() {
      Object.assign(this.filters, {
        tipoServicio: null,
        tipoCliente: null,
        fase: null,
        alertColor: [],
      })
    },
  mounted() {
    this.cleanFilters()
    if (this.showInDashboard) {
      this.filters.tipoServicio = this.GARANTIA
      this.filters.on_site = false
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.wrapper {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}
.buttons-filters {
  display: flex;
  justify-content: start;
  gap: 10px;

  @include media-breakpoint-down(md) {
    justify-content: center;
    width: 100%;
  }
  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
  }
  .buttons-filters {
    display: flex;
    justify-content: start;
    gap: 10px;

    @include media-breakpoint-down(md) {
      justify-content: center;
      width: 100%;
    }
    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
    }
  }
  .filter-btn {
    border-radius: 5px;
    // width: 175px;
    width: 140px;
    height: 45px;
    line-height: 1;
    @include media-breakpoint-only(md) {
      flex-grow: 1;
      width: auto;
      min-width: 100px;
    }
    @include media-breakpoint-down(sm) {
      width: calc(50% - 10px);
    }
    &.disabled.active {
      background-color: #727cac;
      color: #fff;
    }
  }
  ::v-deep .btn-secondary,
  .btn-secondary:hover {
    color: #fff;
    font-weight: bold;
    background-color: #5b73e8;
    border-color: #5b73e8;
    opacity: 1;
  }
  ::v-deep .btn-warning,
  .btn-warning:hover {
    color: #202223;
    font-weight: bold;
    background-color: #f1b44c;
    border-color: #f1b44c;
    opacity: 0.8;
  }
  ::v-deep .btn-info,
  .btn-info:hover {
    color: #202223;
    font-weight: bold;
    background-color: #0096db;
    border-color: #0096db;
    opacity: 0.8;
  }
  ::v-deep .btn-success,
  .btn-success:hover {
    color: #202223;
    font-weight: bold;
    background-color: #34c38f;
    border-color: #34c38f;
    opacity: 0.9;
  }

}
@include media-breakpoint-down(sm) {
   .btn-disabled {
       background-color: white !important;
       color: #74788d !important;
     }

  }
</style>
