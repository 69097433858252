<template lang="pug">
  .edit-rma
    div
      button.change-button(
        v-if="noButton == null"
        :disabled="disabled"
        @click="showModal()"
      ) Ingresa RMA
    //- modal
    b-modal.hide-footer(
    id="'rma-modal'"
    size="lg"
    v-model="modal"
    )
      template(#modal-title)
        span.header-modal.text-center R.M.A para OT {{ ot.ot }}
      div.d-block
        p.font-select.text-center {{modalMessage}}
        b-form.short-line-height
         b-row
            b-col
              b-form-group(
                label= "Nombre y apellido:"
                label-for="input-rma"
                v-if="ot.cliente && ot.cliente.tipo_cliente !== 'Empresa'"
                ) {{ot.cliente && ot.cliente.tipo_cliente !== 'Empresa' ?  ot.cliente.persona.nombre + ' ' + ot.cliente.persona.apellido : '---' }}
              b-form-group(
                label= "Razón social:"
                label-for="input-rma"
                v-else
                ) {{ot.cliente && ot.cliente.tipo_cliente === 'Empresa' ? ot.cliente.empresa.razon_social : '---'}}
              b-form-group(
                label= "Número de teléfono:"
                label-for="input-rma"
                ) {{ot.cliente && ot.cliente.tipo_cliente === 'Empresa' ? (ot.sucursal ? ot.sucursal.telefono : '-') : ot.cliente.persona.telefono }}
              b-form-group(
                label= "Correos:"
                label-for="input-rma"
                ) {{ot.cliente && ot.cliente.tipo_cliente === 'Empresa' ? getEmail(ot.cliente.empresa.correo_empresas) : ot.cliente.persona.email}}
              b-form-group(
                label= "Ciudad:"
                label-for="input-rma"
                ) {{ot.cliente && ot.cliente.tipo_cliente === 'Empresa' ? (ot.sucursal ? ot.sucursal.comuna : '-') : ot.cliente.persona.comuna }}
              label.sub-title(for="input-rma")
            b-col
              b-form-group(
                label= "Fecha de compra:"
                label-for="input-rma"
              ) {{ot.cliente && ot.cliente.tipo_cliente === 'Empresa' ? ot.fecha_de_compra : ot.fecha_de_compra}}
              b-form-group(
                label= "Reporte de Falla:"
                label-for="input-rma"
              ) {{ot.reporte_falla_cliente}}
              b-form-group(
                label= "Numero de serie:"
                label-for="input-rma"
                v-if="ot.equipo"
              ) {{ot.equipo.serial_number}}
              b-form-group(
                label= "Descargar Boleta"
                label-for="input-rma"
                v-if="ot.equipo"
              )
                a(v-if="ot.boleta" :href="ot.boleta" target="_blank") Descargar
                p(v-else) -
        b-form-group
          label.sub-title(for="input-rma") R.M.A
          b-form-input(
            :formatter="formatter"
            style="text-transform:uppercase;"
            id="input-rma"
            v-model="rma"
          )
          b-form-invalid-feedback(:state="$v.rma.minLength")
            | Rma debe ser de 10 caracteres
      template(#modal-footer='{ ok, cancel, hide }')
        b-button(size='sm' variant='success' @click='saveRMA()', :disabled="rma === null || rma.length !== 10 || loading") Si
        b-button(size='sm' variant='danger' @click='hideModal()') Cancelar
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { required, minLength } from 'vuelidate/lib/validators'
import { ACTION_NAMES } from '@/utils/enums/actions'

export default {
  name: 'ActionEditRma',
  props: ['ot', 'disabled', 'noButton'],
  data() {
    return {
      rma: this.ot ? this.ot.rma : null,
      modal: false,
      loading: false,
      modalMessage:
        'Para terminar la asignación de la O.T correctamente, por favor ingresa el R.M.A que corresponda a continuación:',
    }
  },
  validations: {
    rma: {
      required,
      minLength: minLength(10),
    },
  },
  computed: {
    ...mapGetters('OTStore', ['statusRequest']),
  },
  methods: {
    ...mapActions('OTStore', ['editOT', 'getOT']),
    showModal() {
      this.modal = true
    },
    hideModal() {
      this.modal = false
    },
    formatter(value) {
      if (!value || !value.length) return null
      return value.toUpperCase().substr(0, 10)
    },
    async saveRMA() {
      this.loading = true
      try {
        await this.editOT({
          ot: this.ot.ot,
          rma: this.rma,
          action: ACTION_NAMES.editRMA,
        })
        console.log({ status: this.statusRequest })
        if (this.statusRequest && this.statusRequest.status < 400) {
          this.$bvToast.toast('RMA actualizado correctamente', {
            variant: 'success',
          })
          setTimeout(() => {
            this.$emit('changed')
            this.hideModal()
          }, 500)
        } else {
          const errorText = this.statusRequest.data ? this.statusRequest.data : 'Error al guardar rma'
          this.$bvToast.toast(errorText, {
            variant: 'danger',
          })
        }
      } catch (err) {
        this.$bvToast.toast(err.response.data, {
          variant: 'danger',
        })
      }
      this.loading = false
    },
    getEmail(stringList) {
      const list = JSON.parse(stringList)
      return list.join(', ')
    },
  },
}
</script>
