import slugify from 'slugify'
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import axios from '../../plugins/axios';

function getHeaders() {
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return config
}

// import getUrl from '../../utils/searchFilter';

// export const listOTByState = ({ commit }, { states, page }) => {
//   const slugified = states.map((e) => slugify(e, { lower: true })).join(',');
//   const apiAddress = `/api/orden/?estados=${slugified}&page=${page || 1}`;
//   const { key } = JSON.parse(localStorage.getItem('user'));
//   const config = {
//     headers: { Authorization: `Token ${key}` },
//   };
//   return axios.get(apiAddress, config)
//     .then((response) => {
//       commit('SET_CURRENT_OTS', { ots: response.data, states });
//     });
// };

/** Acción genérica para consultar un listado de ots bajo diferentes criterios */
export const searchOT = (
  { commit },
  {
    query,
    estados,
    fecha_ingreso,
    tecnico_asignado_id,
    aprobadas_3x3,
    page,
    for_notifications = false,
    tipo_servicio,
    ot_list,
    ordering,
    sucursal,
    filters = null,
    signal
  }
) => {
  // construye query con variables de consulta requeridas
  // const { url, concatenator } = getUrl(query, estados, fecha_ingreso, tecnico_asignado_id, aprobadas_3x3);
  // verifica si es la misma query que se pidió anteriormente para avanzar de página o resetearla
  // const isANewQuery = JSON.stringify(url) !== JSON.stringify(state.lastQuery);
  // const page = isANewQuery ? 1 : state.page;
  // if (!page) return;

  commit('SET_OTS', { OTS: [] })
  commit('SET_IS_SEARCHING', true)

  const { key } = JSON.parse(localStorage.getItem('user'))
  const headers = { Authorization: `Token ${key}` }
  const stringEstados =
    estados && estados.filter((e) => !!e).length > 0
      ? `?estados=${estados.map((e) => slugify(e, { lower: true })).join(',')}`
      : ''
  const stringFiltersServicesType = filters ? filters.servicio !== 0 ? `&tipo_servicio=${filters.servicio}` : '' : ''
  const stringFiltersClientType = filters ? filters.tipo_cliente ? `&tipo_cliente=${filters.tipo_cliente}` : '' : ''
  const stringFiltersTechnician = filters ? filters.tecnico ? `&tecnico_asignado_id=${filters.tecnico}` : '' : ''
  const alertColorFilter = filters?.alert_color?.length > 0 ? filters.alert_color.join(',') : undefined
  const params = {
    query,
    fecha_ingreso,
    tecnico_asignado_id,
    aprobadas_3x3,
    page,
    tipo_servicio,
    ot_list,
    ordering,
    sucursal,
    alert_color__in: alertColorFilter,
  }
  const for_notifications_str =
    stringEstados.length > 0
      ? `&for_notifications=${for_notifications}`
      : `?for_notifications=${for_notifications}`
  return axios
    .get(`api/orden/${stringEstados}${for_notifications_str}${stringFiltersServicesType}${stringFiltersClientType}${stringFiltersTechnician}`, {
      params,
      headers,
      signal
    })
    .then((response) => {
      commit('SET_IS_SEARCHING', false)
      if (for_notifications) {
        commit('SET_NOTIFICATIONS_OTS', { ots: [] })
        commit('SET_NOTIFICATIONS_OTS', { ots: response.data.results })
      } else {
        commit('SET_CURRENT_OTS', {
          ots: response.data,
          states: estados,
        })
        if (query) commit('SET_IS_SEARCHED', true)
        else commit('SET_IS_SEARCHED', false)
      }
    })
}

export const listNotificationsPagination = ({}, page) => {
  const apiAddress = `/api/orden/notifications?page=${page}`
  const config = getHeaders()
  return axios.get(apiAddress, config).catch((error) => error.response)
}

export const getSingleOTAsListData = async ({}, { ot }) => {
  const config = { ...getHeaders(), params: { ot_number: ot } }
  const res = await axios.get('/api/orden/', config)
  return res.data?.results?.[0]
}

export const createOT = ({}, data) => {
  // eslint-disable-line
  const apiAddress = '/api/orden'
  const { token } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  return axios
    .post(apiAddress, data, config)
    .then((response) => response)
    .catch((error) => error.response)
}

export const editOT = ({}, data) => {
  // eslint-disable-line
  const apiAddress = '' // Cuando este lista la api, se coloca la direccion
  const config = {
    headers: {},
  } // Cuando este listo el back coloco la configuracion para el header del request
  return axios
    .put(apiAddress, data, config)
    .then((response) => response)
    .catch((error) => error.response)
}

export const getOtHistory = ({}, otId) => {
  const apiAddress = `/api/orden/${otId}/historial`
  const config = getHeaders()
  return axios.get(apiAddress, config)
}

export const getSillStates = ({ commit }) => {
  const apiAddress = '/api/orden/estados'
  const config = getHeaders()
  return axios.get(apiAddress, config).then((response) => {
    commit('SET_STATES', response.data)
  })
}

export const getDashboard = ({}, data) => {
  const apiAddress = '/api/orden/dashboard'
  const config = getHeaders()
  config.params = data
  return axios.get(apiAddress, config)
}

export const getOtsById = ({}, data) => {
  const apiAddress = `/api/orden/filter_by_id?ot_ids=${data}`
  const config = getHeaders()
  return axios.get(apiAddress, config)
}

export const autocompleteOT = ({ commit }, ordenes) => {
  const apiAddress = `/api/orden/ordenes_autocomplete?ot=${ordenes}`
  const config = getHeaders()
  return axios.get(apiAddress, config).then((response) => {
    commit('SET_OTS', { OTS: response.data })
  })
}

export const getOtExceptionsHistory = ({}, otId) => {
  const apiAddress = `/api/orden/${otId}/exceptions`
  const config = getHeaders()
  return axios.get(apiAddress, config)
}

export const updateOtInCurrentPage = ({ state, commit }, { ot }) => {
  const results = state.currentOts?.results
  if (!results) return

  const index = results.findIndex((o) => o.ot === ot.ot)
  if (index === -1) return

  commit('UPDATE_CURRENT_OT', { index, ot })
  return index
}

export const addOtToResults = ({ state, commit }, { ot }) => {
  const estado = ot.estado.estado.nombre
  commit('CHANGE_ESTADO_COUNT', { estado, diff: 1 })

  const isInCurrentPage = state.currentStates.includes(estado)
  if (isInCurrentPage) {
    commit('ADD_CURRENT_OT', { ot })
  }
}

export const removeOtFromResults = ({ state, commit }, { id }) => {
  const ot = state.currentOts.results.find((o) => o.id === id)
  const estado = ot.estado.estado.nombre
  commit('CHANGE_ESTADO_COUNT', { estado, diff: -1 })

  const isInCurrentPage = state.currentStates.includes(estado)
  if (isInCurrentPage) {
    commit('REMOVE_CURRENT_OT', { ot })
  }
}

// Obtiene los movimientos de inventario asociados a una OT
export const getInventoryMovementsOt = ({}, otId) => {
  const apiAddress = `/api/orden/${otId}/movimientos_inventario`
  const config = getHeaders()
  return axios.get(apiAddress, config)
}

/**
 * Obtiene historial de movimientos de inventario de una OT por pieza
 */
export const getInventoryMovementHistory = ({}, otId) => {
  const apiAddress = `/api/orden/${otId}/historial_movimientos_inventario`
  const config = getHeaders()
  return axios.get(apiAddress, config)
}


export const resendFailedEmail = ({}, ot) => {
  // eslint-disable-line
  const apiAddress = `/api/orden/${ot}/resend_failed_email`
  const { token } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  return axios
    .post(apiAddress, {}, config)
    .then((response) => response)
    .catch((error) => error.response)
}

export const getOtByID = ({}, otId) => {
  const apiAddress = `/api/orden/${otId}`
  const config = getHeaders()
  return axios.get(apiAddress, config)
}

export const setDefaultStates = ({ commit }, data) => {
  commit('SET_DEFAULT_STATES', data)
}
