<template lang="pug">
  div
    Navbar
    .container-md
      h4.main-header Preingreso Clientes Envío
      b-link( @click="cancel" ).cancelar Cancelar
      DataClientRegions(
      :returnClient = "formClient.datosProvisorios"
      v-if="step===1"
      :setDatosProvisorios = "setDataClient")
      WarrantyFailsRegions(
      :setDataWarranty = "setDataWarrantyFails"
      :warrantyReturn = "formClient.formWarranty"
      :safeBoleta = "setBoleta"
      :boleta = "formClient.boleta"
      @goBack = "getBack"
      v-if="step===2"
      )
      DataEquipmentRegions(
      v-show="step===3"
      :returnEquipment = "formEquipment"
      :setFormEquipment = "setDataEquipment"
      @goBack = "getBack"
      )
      ResumeRegions(
      v-if="step===4"
      :dataClient= "formClient.datosProvisorios"
      :warrantyFail= "formClient.formWarranty"
      :dataEquipment= "formEquipment"
      @goBack = "getBack"
      @finish = "cancel"
      :boleta="boleta"
      )
    Footer.footer
</template>

<script>
import Navbar from '@/components/OT/ClientOT/Navbar/Navbar.vue'
import Footer from '@/components/UI/Footer.vue'
import DataClientRegions from './FormRegiones/DataClientRegions.vue'
import ResumeRegions from './FormRegiones/ResumeRegions.vue'
import WarrantyFailsRegions from './FormRegiones/WarrantyFailsRegions.vue'
import DataEquipmentRegions from './FormRegiones/DataEquipmentRegions.vue'

export default {
  name: 'PreIngressRegions',
  components: {
    DataClientRegions,
    WarrantyFailsRegions,
    ResumeRegions,
    DataEquipmentRegions,
    Navbar,
    Footer,
  },
  data() {
    return {
      formClient: {
        datosProvisorios: {
          rut: null,
          nombre: '',
          apellido: '',
          email: null,
          telefono: null,
          direccion: null,
          comuna: null,
          region: null,
          tipo_cliente: null,
          razon_social: null,
          nombre_sucursal: null,
        },
        formWarranty: {
          tipo_servicio: null,
          reporte_falla_cliente: null,
          fecha_de_compra: null,
        },
      },
      formEquipment: {
        serial_number: null,
        password: null,
        size_hard_drive: null,
        power_source: null,
        total_ram_memorie: null,
        equipmentType: null,
        model_name: null,
        modelo: null,
      },
      boleta: '',
      step: 1,
    }
  },
  computed: {},
  methods: {
    setDataClient(datosProvisorios) {
      this.formClient.datosProvisorios = datosProvisorios
      this.step += 1
    },
    setDataWarrantyFails(formWarranty) {
      this.formClient.formWarranty = formWarranty
      this.step += 1
    },
    setDataEquipment(formEquipment) {
      this.formEquipment = formEquipment
      this.step += 1
    },
    setBoleta(boleta) {
      this.boleta = boleta
    },
    getBack() {
      this.step -= 1
    },
    cancel() {
      this.formClient = {
        datosProvisorios: {
          rut: null,
          nombre: null,
          apellido: null,
          email: null,
          telefono: null,
          direccion: null,
          comuna: null,
          region: null,
        },
        formWarranty: {
          tipo_servicio: null,
          reporte_falla_cliente: null,
          fecha_de_compra: null,
        },
        boleta: '',
        formEquipment: {
          serial_number: null,
          password: null,
          size_hard_drive: null,
          power_source: null,
          total_ram_memorie: null,
          equipmentType: null,
          model_name: null,
        },
      }
      this.step = 1
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/style/cliente.scss';

::v-deep.navbar {
  margin-bottom: 30px;
}
::v-deep.navbar-expand {
  box-shadow: 0px 3px 6px #00000029;
}
::v-deep.sesion-item {
  color: #74788d;
  font-size: 15px;
  font-weight: bold;
}
::v-deep.color-icon {
  font-weight: bold;
  color: #5b73e8;
  font-size: 1.2em;
  margin-left: 6px;
}
.footer {
  /* position: absolute; */
  bottom: 0;
  padding-bottom: 1rem;
  padding-top: 1rem;
  text-wrap-mode: nowrap;
  width: 100%;
}
</style>
