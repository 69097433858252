var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"d-flex justify-content-start"},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"tools","variant":"primary","font-scale":"1.5"}}),_c('span',{staticClass:"title"},[_vm._v("Historial Movimientos de Inventario")])],1)]),_c('hr'),_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.switchTableOld),callback:function ($$v) {_vm.switchTableOld=$$v},expression:"switchTableOld"}},[_vm._v("Mostrar tabla antigua")]),_c('b-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.switchTableOld),expression:"switchTableOld"}],attrs:{"items":_vm.statesItemsOld,"busy":_vm.loading,"hover":"","responsive":""},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]},proxy:true}])}),(_vm.statesItemsOld.length === 0)?_c('h4',{staticClass:"text-center"},[_vm._v("No hay registros")]):_vm._e(),_c('b-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.switchTableOld),expression:"!switchTableOld"}],attrs:{"items":_vm.statesItems,"busy":_vm.loading,"hover":"","responsive":"","fields":_vm.fields},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]},proxy:true},{key:"cell(solicitud)",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(value)))])]}},{key:"cell(llegada)",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(value)))])]}},{key:"cell(entrega)",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(value)))])]}},{key:"cell(Drop)",fn:function(ref){
var item = ref.item;
return [(item.Drop)?_c('div',{staticClass:"flex-col"},[_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.Drop.fecha)))]),_c('span',[_vm._v(_vm._s(item.Drop.user))])]):_c('span',[_vm._v("-")])]}},{key:"cell(Bad)",fn:function(ref){
var item = ref.item;
return [(item.Bad)?_c('div',{staticClass:"flex-col"},[_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.Bad.fecha)))]),_c('span',[_vm._v(_vm._s(item.Bad.user))])]):_c('span',[_vm._v("-")])]}}])}),(_vm.statesItems.length === 0)?_c('h4',{staticClass:"text-center"},[_vm._v("No hay registros")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }