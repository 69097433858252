<template lang="pug">
.flex-col.gap-2
  h3.subtitle Datos del Equipo
  .flex-col.gap-1.mb-3
    b-row(no-gutters)
      b-col(cols="5" md="3")
        .label.label-text N° de Serie
      b-col(cols="7" md="9")
        span {{ ot.equipo.serial_number }}
    b-row(no-gutters)
      b-col(cols="5" md="3")
        .label Marca
      b-col(cols="7" md="9")
        span {{ ot.equipo.modelo_detail.marca.marca_name }}
    b-row(no-gutters)
      b-col(cols="5" md="3")
        .label Tipo
      b-col(cols="7" md="9")
        span {{ ot.equipo.modelo_detail.dispositive_type }}
    b-row(no-gutters)
      b-col(cols="5" md="3")
        .label Modelo
      b-col(cols="7" md="9")
        span {{ ot.equipo.modelo_detail.model_name }}
    b-row(no-gutters)
      b-col(cols="5" md="3")
        .label Código Retail
      b-col(cols="7" md="9")
        span {{ ot.codigo_retail || '---' }}

  h3.subtitle Datos del Cliente
  .flex-col.gap-1.mb-3(v-if="ot.cliente.tipo_cliente === 'Persona'")
    b-row(no-gutters)
      b-col(cols="5" md="3")
        .label.label-text R.U.T.
      b-col(cols="7" md="9")
        span {{ ot.cliente.persona.rut }}
    b-row(no-gutters)
      b-col(cols="5" md="3")
        .label Nombre
      b-col(cols="7" md="9")
        span {{ ot.cliente.persona.nombre }} {{ ot.cliente.persona.apellido }}
  .flex-col.gap-1.mb-3(v-else)
    b-row(no-gutters)
      b-col(cols="5" md="3")
        .label.label-text R.U.T.
      b-col(cols="7" md="9")
        span {{ ot.cliente.empresa.rut }}
    b-row(no-gutters)
      b-col(cols="5" md="3")
        .label Razón Social
      b-col(cols="7" md="9")
        span {{ ot.cliente.empresa.razon_social }}
    b-row(v-if="ot.sucursal")(no-gutters)
      b-col(cols="5" md="3")
        .label Sucursal
      b-col(cols="7" md="9")
        span {{ ot.sucursal.nombre }}

  h3.subtitle Tipo de Retiro
  .flex-col.gap-1.mb-3
    b-row(no-gutters)
      b-col(cols="5" md="3")
        .label.label-text Retiro Actual
      b-col(cols="7" md="9")
        .flex-col.flex-md-row.justify-content-md-between
          span.mb-2.mb-md-0 {{ tipoDespacho }}
          ChangeDeliveryButton.mt-md-n2(:ot="ot" :public="true")
</template>

<script>
import { LABEL_TIPO_DESPACHO } from '@/utils/labels'
import ChangeDeliveryButton from '@/components/OT/ClientOT/ChangeDeliveryButton.vue'

export default {
  props: {
    ot: {
      type: Object,
      required: true,
    },
  },

  components: { ChangeDeliveryButton },

  computed: {
    tipoDespacho() {
      if (!this.ot.despacho) return '---'
      return LABEL_TIPO_DESPACHO[this.ot.despacho]
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../style/colors.scss';

.label-text {
  color: $samtek-black !important;
}
</style>
