<template lang="pug">
div
  h3.subtitle.mb-2 Historial
  .flex-col.gap-3
    .flex-row.state-row(v-for="state in states" :key="`${state.fecha_hora} + ${state.estado.id}`")
      .flex-col.datetime-col
        strong {{ state.fecha_hora | date }}
        span {{ state.fecha_hora | time }}
      .lamp-col
        .lamp
      .flex-col
        strong {{ state.estado.nombre_cliente }}
        span.description {{ state.estado.descripcion_cliente }}
</template>

<script>
import moment from 'moment'

export default {
  props: {
    states: {
      type: Array,
      required: true,
    },
  },

  filters: {
    date(value) {
      return moment(value).format('DD MMM YYYY')
    },
    time(value) {
      return moment(value).format('HH:mm [Hrs]')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../style/colors.scss';

.datetime-col {
  flex: 0 0 100px;
  > * {
    white-space: nowrap;
  }
}

.lamp-col {
  width: 120px;
  align-items: center;
}

.lamp {
  width: 20px;
  height: 20px;
  margin: 0 50px;
  border-radius: 50%;
  background-color: #e5e5e5;
}

.state-row:first-child .lamp {
  background-color: $samtek-yellow;
}

.state-row:not(:last-child) {
  flex: 0 0 20px;
  .lamp-col {
    position: relative;
  }
  .lamp {
    &::after {
      content: '';
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 2px;
      height: calc(100% + 5px);
      background-color: #e5e5e5;
    }
  }
}

@media (max-width: 767px) {
  .description {
    display: none;
  }
}
</style>
